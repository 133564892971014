
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import PaymentSidebarHeader from '@/components/PaymentSidebarHeader.vue'
import sidebar from '@/store/modules/sidebar'
import payments from '@/services/payments'
import CUSidebarContent from '@/layouts/CUSidebarContent.vue'
import CUSidebarContentWithFooterButtons from '@/components/CUSidebarContentWithFooterButtons.vue'
import { ReservationAddRefundRequest } from '@/models/dto/Refund'
import auth from '@/store/modules/auth'
import rstate from '@/store/modules/reservation'
import reservation from '@/services/reservation'
import { EventBus } from '@/utils/eventBus'
import { isNotEmptyInput } from '@/utils/validators'
import RecipientList from '@/components/RecipientList.vue'
import { Reservation } from '@/models/dto'
import PaymentSidebarBulkReservationsHeader from '@/components/PaymentSidebarBulkReservationsHeader.vue'
import customer from '@/services/customer'
@Component({
  components: {
    PaymentSidebarHeader,
    CUSidebarContent,
    CUSidebarContentWithFooterButtons,
    RecipientList,
    PaymentSidebarBulkReservationsHeader,
  },
})
export default class AddRefundSidebar extends Vue {
  @Prop({ default: undefined, required: false })
  readonly bulkReservations!: Reservation[]

  refundTypes = []
  isNotEmptyInput = isNotEmptyInput
  loading = false
  contacts = []
  formData = {
    paymentNotes: '',
    internalNotes: '',
    amount: 0,
    refundType: null,
    sendEmail: true,
  }
  displayedReservations = []

  @Watch('bulkReservations', { immediate: true })
  onBulkReservationChange(reservations: Reservation[]): void {
    this.displayedReservations = reservations
  }

  cancel(): void {
    sidebar.pop()
  }

  get confirmationEmails(): string[] {
    if (!this.formData.sendEmail) {
      return []
    }
    return this.contacts.map((u) => u.email)
  }

  get isMultiReservation(): boolean {
    return !!this.bulkReservations?.length
  }

  get reservationIds(): number[] {
    if (this.isMultiReservation) {
      return this.displayedReservations.map((r) => r.reservationId)
    } else {
      return [rstate.reservation.reservationId]
    }
  }

  handleRemoveBulkReservation(reservationId: number): void {
    this.displayedReservations = this.displayedReservations.filter(
      (res) => res.reservationId !== reservationId
    )
    if (this.displayedReservations.length === 0) {
      sidebar.pop()
    }
  }

  async loadContacts(): Promise<void> {
    let bookingContact
    let billingContactId

    if (this.isMultiReservation) {
      const reservation = this.displayedReservations[0]
      const {
        customerEmail: email,
        customerFirstName: firstName,
        customerLastName: lastName,
        customerId: id,
      } = reservation
      bookingContact = { email, firstName, lastName, id }
      billingContactId = reservation.billingCustomerId
    } else {
      const {
        customerEmail: email,
        customerFirstName: firstName,
        customerLastName: lastName,
        customerId: id,
      } = rstate.reservation
      bookingContact = { email, firstName, lastName, id }
      billingContactId = rstate.reservation.billingCustomerId
    }

    this.contacts.push({ ...bookingContact, type: 'Booking' })
    if (billingContactId) {
      const { data: billingCustomer } = await customer.byId(billingContactId)
      this.contacts.push({ ...billingCustomer.customer, type: 'Billing' })
    }
  }

  async submit(): Promise<void> {
    if (!this.$refs['form']['validate']()) {
      return
    }

    if (this.isMultiReservation) {
      console.log('> Unsupported Payment Method')
      this.handleSubmitSuccess()
      return
    }

    this.loading = true
    const payload: ReservationAddRefundRequest = {
      accountCreditAmount: 0,
      amount: String(this.formData.amount),
      companyId: auth.getCompanyId,
      notes: {
        html: this.formData.internalNotes,
        note: this.formData.internalNotes,
      },
      customerNotes: {
        html: this.formData.paymentNotes,
        note: this.formData.paymentNotes,
      },
      payoutAmount: String(this.formData.amount),
      refundType: this.formData.refundType,
      reservationId: rstate.reservation.reservationId,
      sendEmail: this.formData.sendEmail,
      userId: auth.getUserId,
      confirmationEmails: this.confirmationEmails,
    }

    try {
      const res = await reservation.addReservationRefund(payload)
      if (res.status === 200) {
        this.handleSubmitSuccess()
      }
    } catch (e: any) {
      console.error(e)
      EventBus.$emit('snackbar:error', e.response.data.message)
      this.loading = false
    }
  }

  handleSubmitSuccess(): void {
    this.loading = false
    sidebar.close()
    EventBus.$emit('snackbar:success', 'Refund logged successfully!')
    EventBus.$emit('refresh-reservation')
  }

  @Watch('contacts')
  onContactsChange(): void {
    this.formData.sendEmail = !!this.contacts.length
  }

  async mounted(): Promise<void> {
    await this.loadContacts()
    const res = await payments.getRefundTypes()
    this.refundTypes = res.data
  }
}


import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { capitalizeFirstLetter, currencyFilter } from '@/utils/string'
import { isNotEmptyInput, isCardNumberValidLength } from '@/utils/validators'
import payments from '@/services/payments'
import { states } from '@/utils/states'
import {
  paymentGatewayLabels,
  tokenizeAuthNetCard,
  buildAuthNetTokenizePayload,
} from '@/utils/payment'
import RecipientList from '@/components/RecipientList.vue'
import {
  CustomerPaymentProfile,
  PaymentInstrument,
} from '@/models/dto/PaymentProfile'
import {
  PaymentGatewayConvertPayload,
  SquareCardFormPaymentInfo,
  TokenizeCreditCardPayload,
} from '@/models/PaymentGateway'
import sidebar from '@/store/modules/sidebar'
import SquareCardForm from '@/components/SquareCardForm.vue'
import auth from '@/store/modules/auth'
import rstate from '@/store/modules/reservation'
import rservice from '@/services/reservation'
import {
  Address,
  Reservation,
  ReservationProcessPaymentRequest,
} from '@/models/dto'
import { EventBus } from '@/utils/eventBus'
import PaymentSidebarHeader from '@/components/PaymentSidebarHeader.vue'
import PaymentSidebarBulkReservationsHeader from '@/components/PaymentSidebarBulkReservationsHeader.vue'
import ProcessingFeePercentageField from '@/components/ProcessingFeePercentageField.vue'
import AutocompleteAddress from '@/components/AutocompleteAddress.vue'
import customer from '@/services/customer'
import currency from 'currency.js'
import { busifyPayBaseUrl } from '@/utils/env'
import { WindowMessage } from '@/models/Message'
import { v4 } from 'uuid'
import BusifyPayReservationPaymentForm from '@/components/BusifyPayReservationPaymentForm.vue'
import PaymentSidebarNoteAndRecipients from './PaymentSidebarNoteAndRecipients.vue'
import PaymentLoadingScreen from '@/components/PaymentLoadingScreen.vue'
import { InstrumentType } from '@/utils/enum'
@Component({
  components: {
    RecipientList,
    SquareCardForm,
    PaymentSidebarBulkReservationsHeader,
    PaymentSidebarHeader,
    ProcessingFeePercentageField,
    AutocompleteAddress,
    BusifyPayReservationPaymentForm,
    PaymentSidebarNoteAndRecipients,
    PaymentLoadingScreen,
  },
})
export default class ReservationProcessPaymentSidebar extends Vue {
  @Prop({ default: false, type: Boolean }) isSingleReservationPayment!: boolean
  @Prop({ default: undefined, required: false })
  readonly bulkReservations!: Reservation[]

  currencyFilter = currencyFilter
  isNotEmptyInput = isNotEmptyInput
  isCardNumberValidLength = isCardNumberValidLength
  auth = auth
  states = states
  DEFAULT_PROCESSING_FEE = 3
  displayedReservations = []
  busifyPayPaymentMethods = [
    { text: 'Card', value: 'card' },
    { text: 'Bank', value: 'bank' },
  ]
  displayBusifyPayReservationsError = false

  paymentIntegrations = []
  contacts = []
  customerPaymentProfiles: Partial<CustomerPaymentProfile>[] = [
    { customerPaymentProfileId: -1, text: 'Add New Card' },
  ]

  loading = false
  sessionId = ''

  formData = {
    paymentAmount: '0',
    processingFeePercentage: 0,
    paymentNotes: '',
    internalNotes: '',
    number: '',
    expiration: '',
    cvc: '',
    name: '',
    address: {
      street: null,
      state: null,
      zip: null,
    },
    savePaymentMethod: false,
    selectedPaymentProfile: -1,
    sendEmailToCustomer: true,
    selectedPaymentIntegration: null,
    selectedPaymentMethod: 'card',
  }

  @Watch('bulkReservations', { immediate: true })
  onBulkReservationChange(reservations: Reservation[]): void {
    this.displayedReservations = reservations
  }

  get isMultiReservationPayment(): boolean {
    return !!this.bulkReservations?.length
  }

  get processingFeeAmount(): number {
    return currency(this.formData.paymentAmount)
      .multiply(this.formData.processingFeePercentage)
      .divide(100).value
  }

  get totalPaymentAmount(): number {
    const processingFeeAmount = this.processingFeeAmount || 0
    return currency(this.formData.paymentAmount).add(processingFeeAmount).value
  }

  get bookingCustomerId(): number {
    const contact = this.contacts.find((c) => c.type === 'Booking')
    return contact?.customerId || contact?.id
  }

  get dueNow(): number {
    return this.reservationTotalBalance
  }

  get reservationTotalBalance(): number {
    if (this.isMultiReservationPayment) {
      return this.displayedReservations.reduce((sum, r) => r.balance + sum, 0)
    }
    return rstate.reservation.balance
  }

  get reservationAmountAsCents(): number {
    return currency(this.formData.paymentAmount).multiply(100).value
  }

  get reservationIds(): number[] {
    if (this.isMultiReservationPayment) {
      const reservationIds = this.displayedReservations.map(
        (r) => r.reservationId
      )
      reservationIds.sort((a, b) => (a < b ? -1 : 1))
      return reservationIds
    } else {
      return [rstate.reservation.reservationId]
    }
  }

  get shouldAddNewCard(): boolean {
    return this.formData.selectedPaymentProfile === -1
  }

  get isSquare(): boolean {
    return (
      this.formData.selectedPaymentIntegration?.paymentGatewayTypeKey ===
      'square'
    )
  }

  get isBusifyPay(): boolean {
    return (
      this.formData.selectedPaymentIntegration?.paymentGatewayTypeKey ===
      'busify_pay'
    )
  }

  get isAuthNet(): boolean {
    return (
      this.formData.selectedPaymentIntegration?.paymentGatewayTypeKey ===
      'auth_net'
    )
  }

  get confirmationEmails(): string[] {
    if (!this.formData.sendEmailToCustomer) {
      return []
    }
    return this.contacts.map((u) => u.email)
  }

  get clientKey(): string {
    return this.formData.selectedPaymentIntegration?.clientKey
  }

  // Initial loading methods
  async loadPaymentGateways(): Promise<void> {
    const allGatewaysRes = await payments.getAllPaymentGateways()
    let { paymentGateways: installedGateways } = allGatewaysRes.data
    installedGateways = installedGateways.map((g) => {
      return {
        ...g,
        paymentGatewayLabel: paymentGatewayLabels[g.paymentGatewayTypeKey],
      }
    })
    if (!installedGateways.length) {
      return
    }

    this.paymentIntegrations = installedGateways

    const defaultPaymentGateway = installedGateways.find(
      (g) => g.defaultGateway
    )

    this.formData.selectedPaymentIntegration =
      defaultPaymentGateway || this.paymentIntegrations[0]
  }

  handleRemoveBulkReservation(reservationId: number): void {
    this.displayedReservations = this.displayedReservations.filter(
      (res) => res.reservationId !== reservationId
    )
    if (this.displayedReservations.length === 0) {
      sidebar.close()
    }
    this.loadDueNow()
  }

  submitBusifyPayReservationsForm(): void {
    const busifyPayCardForm: any = this.$refs['busify-pay-res-form']
    if (!busifyPayCardForm) {
      return
    }
    busifyPayCardForm.submit()
  }

  handleBusifyPayError(errorMessage: Record<string, unknown>): void {
    this.loading = false
    const message =
      errorMessage?.statusCode === 400
        ? errorMessage?.errorMessage
        : 'There was an error processing payment. Please confirm all fields and try again.'
    EventBus.$emit('snackbar:error', message)
  }

  handleBusifyPayInvalidForm(): void {
    this.loading = false
  }

  async handleBusifyPayReservationSuccess(
    message: WindowMessage
  ): Promise<void> {
    let description = ''
    const { paymentId } = message.messageData as any
    const { brand, instrument_type, last_four } = message.messageData
      ?.instrument as PaymentInstrument
    if (instrument_type === InstrumentType.CARD) {
      description = `${brand} - ${last_four}`
    } else {
      description = 'Online Bank'
    }

    const payment_method =
      this.formData.selectedPaymentMethod === 'card' ? 'credit_card' : 'ach'

    const amount = currency(this.formData.paymentAmount).value
    const amountWithProcessingFee = currency(amount).add(
      this.processingFeeAmount
    ).value

    const payload = {
      amount: amountWithProcessingFee,
      reservationIds: this.reservationIds,
      sendEmail: this.formData.sendEmailToCustomer,
      billing: null,
      confirmationEmails: this.confirmationEmails,
      paymentId,
      payment_method,
      description,
      processingFeeAmount: String(this.processingFeeAmount),
      isManual: true,
      sessionId: this.sessionId,
    }

    try {
      const res = await rservice.addReservationsPaymentWithBusifyPay(payload)
      if (res.status === 200) {
        this.handleSubmitSuccess()
      } else {
        throw new Error('Error adding reservation payment')
      }
    } catch (e) {
      // If there's an error taking a payment on the reservation, display the error message and scroll to the error
      EventBus.$emit('snackbar:error', 'Error updating the reservation')
      this.displayBusifyPayReservationsError = true
      this.$nextTick(() => {
        document
          .getElementById('busify-pay-res-error')
          .scrollIntoView({ behavior: 'smooth' })
      })
    } finally {
      this.loading = false
    }
  }

  handleMessageEvent(event: MessageEvent): void {
    const { origin, data }: { origin: string; data: WindowMessage } = event
    if (origin !== busifyPayBaseUrl()) {
      return
    }
    if (data.messageName === 'payment-failure') {
      this.handleBusifyPayError(data.messageData)
    } else if (data.messageName === 'payment-success') {
      this.handleBusifyPayReservationSuccess(event.data)
    } else {
      this.handleBusifyPayInvalidForm()
    }
  }

  async loadCustomerPaymentProfiles(): Promise<void> {
    const customerIds = []

    if (this.isMultiReservationPayment) {
      customerIds.push(this.displayedReservations[0].customerId)
      if (this.displayedReservations[0].billingContactId) {
        customerIds.push(this.displayedReservations[0].billingContactId)
      }
    } else {
      customerIds.push(rstate.reservation.customerId)
      if (rstate.reservation.billingContactId) {
        customerIds.push(rstate.reservation.billingContactId)
      }
    }

    const res = await payments.getCustomerPaymentProfiles(
      { pageSize: -1, page: 1 },
      customerIds
    )

    const paymentProfiles = res.data.resultList.map((p) => ({
      ...p,
      text: `${capitalizeFirstLetter(p.label)} ending in ${p.mask}`,
    }))
    this.customerPaymentProfiles =
      this.customerPaymentProfiles.concat(paymentProfiles)
  }

  async loadContacts(): Promise<void> {
    let bookingContact
    let billingContactId
    if (this.isMultiReservationPayment) {
      const reservation = this.displayedReservations[0]
      const {
        customerEmail: email,
        customerFirstName: firstName,
        customerLastName: lastName,
        customerId: id,
      } = reservation
      bookingContact = { email, firstName, lastName, id }
      billingContactId = reservation.billingCustomerId
    } else {
      const {
        customerEmail: email,
        customerFirstName: firstName,
        customerLastName: lastName,
        customerId: id,
      } = rstate.reservation
      bookingContact = { email, firstName, lastName, id }
      billingContactId = rstate.reservation.billingCustomerId
    }

    this.contacts.push({ ...bookingContact, type: 'Booking' })
    if (billingContactId) {
      const { data: billingCustomer } = await customer.byId(billingContactId)
      this.contacts.push({ ...billingCustomer.customer, type: 'Billing' })
    }
  }

  loadDueNow(): void {
    this.formData.paymentAmount = this.dueNow.toFixed(2)
  }

  cancel(): void {
    sidebar.pop()
  }

  submit(): void {
    this.reservationSubmit()
  }

  async reservationSubmit(): Promise<void> {
    const form: any = this.$refs.form
    if (!form.validate()) {
      return
    }

    this.loading = true

    let totalBalance = 0
    if (this.isMultiReservationPayment) {
      totalBalance = this.displayedReservations.reduce(
        (sum, r) => r.balance + sum,
        0
      )
    } else {
      totalBalance = rstate.reservation.balance
    }

    if (Number(this.formData.paymentAmount) > totalBalance) {
      EventBus.$emit(
        'snackbar:error',
        'Payment is greater than the reservation balance.'
      )
      this.loading = false
      return
    }

    if (this.isAuthNet) {
      if (this.shouldAddNewCard) {
        const authNetTokenizePayload = buildAuthNetTokenizePayload(
          this.formData
        )
        const reservation = this.isMultiReservationPayment
          ? this.displayedReservations[0]
          : rstate.reservation
        const tokenizedCardInfo: PaymentGatewayConvertPayload =
          await tokenizeAuthNetCard(
            authNetTokenizePayload,
            reservation,
            this.formData.selectedPaymentIntegration
          )
        await this.processResPaymentWithCard(
          tokenizedCardInfo,
          authNetTokenizePayload
        )
      } else {
        this.processResPaymentWithExistingProfile()
      }
    } else if (this.isSquare) {
      await this.tokenizeSquareCard()
    } else if (this.isBusifyPay) {
      this.submitBusifyPayReservationsForm()
    }
  }

  get isQuickbooksAutomaticInvoiceCreation(): boolean {
    return auth.getCompanyQuickBooksSettings.automaticInvoiceCreation || false
  }

  async tokenizeSquareCard(): Promise<void> {
    const squarePaymentForm: any = this.$refs['square-payment-form']
    if (!squarePaymentForm) {
      return
    }
    try {
      await squarePaymentForm.requestCardNonce()
    } catch (e) {
      this.loading = false
      return
    }
  }

  async handleSquareNonce(
    paymentInfo: SquareCardFormPaymentInfo
  ): Promise<void> {
    const { nonce } = paymentInfo
    const tokenizedPaymentInfo: PaymentGatewayConvertPayload = {
      tokens: [nonce],
      paymentGateway: {
        key: 'square',
        id: this.formData.selectedPaymentIntegration.companyPaymentGatewayId,
        clientId: this.formData.selectedPaymentIntegration.clientKey,
      },
    }

    const paymentMethodData: TokenizeCreditCardPayload = {
      activeMethod: 'credit_card',
      name: '',
      cardholderName: '',
      cardNumber: '',
      securityCode: '',
      mask: paymentInfo.last_4,
      type_label: paymentInfo.card_brand,
      ...paymentInfo,
    }

    await this.processResPaymentWithCard(
      tokenizedPaymentInfo,
      paymentMethodData
    )
  }

  handleSubmitSuccess(): void {
    this.loading = false
    sidebar.close()

    if (this.isMultiReservationPayment) {
      EventBus.$emit('refresh-tableview')
      EventBus.$emit('snackbar:success', 'Payment processed successfully!')
    } else {
      EventBus.$emit('refresh-reservation')
      EventBus.$emit('snackbar:success', 'Payment processed successfully!')
    }
  }

  async processResPaymentWithCard(
    tokenizedCardInfo: PaymentGatewayConvertPayload,
    paymentMethodInfo: TokenizeCreditCardPayload
  ): Promise<void> {
    const payload: ReservationProcessPaymentRequest = {
      amount: String(this.totalPaymentAmount),
      reservationIds: this.reservationIds,
      sendEmail: this.formData.sendEmailToCustomer,
      confirmationEmails: this.confirmationEmails,
      nonces: tokenizedCardInfo.tokens,
      payment_gateway: {
        id: this.formData.selectedPaymentIntegration.companyPaymentGatewayId,
        key: this.formData.selectedPaymentIntegration.paymentGatewayTypeKey,
      },
      payment_method: 'credit_card',
      description: `Card - ${capitalizeFirstLetter(
        paymentMethodInfo.type_label
      )} ${paymentMethodInfo.mask}`,
      billing: paymentMethodInfo,
      processingFeeAmount: String(this.processingFeeAmount),
    }

    if (this.formData.internalNotes) {
      payload.notes = { note: this.formData.internalNotes }
    }

    if (this.formData.paymentNotes) {
      payload.customerNotes = { note: this.formData.paymentNotes }
    }

    try {
      const [res, _] = await Promise.all([
        rservice.addReservationPayment(payload),
        rservice.clearAmountDue(this.reservationIds),
      ])
      if (res.status === 200) {
        this.handleSubmitSuccess()
      }
    } catch (e: any) {
      console.error(e)
      EventBus.$emit('snackbar:error', e.response.data.message)
      this.loading = false
    }
  }

  async processResPaymentWithExistingProfile(): Promise<void> {
    const payload: ReservationProcessPaymentRequest = {
      amount: String(this.totalPaymentAmount),
      reservationIds: this.reservationIds,
      paymentProfileId: this.formData.selectedPaymentProfile,
      sendEmail: this.formData.sendEmailToCustomer,
      confirmationEmails: this.confirmationEmails,
      payment_gateway: {
        id: this.formData.selectedPaymentIntegration.companyPaymentGatewayId,
        key: this.formData.selectedPaymentIntegration.paymentGatewayTypeKey,
      },
      payment_method: 'credit_card',
      processingFeeAmount: String(this.processingFeeAmount),
    }

    const customerProfile = this.customerPaymentProfiles.find((profile) => {
      return (
        profile.customerPaymentProfileId ===
        this.formData.selectedPaymentProfile
      )
    })
    if (customerProfile) {
      payload.description = `Card - ${capitalizeFirstLetter(
        customerProfile.label
      )} ${customerProfile.mask}`
    }

    if (this.formData.internalNotes) {
      payload.notes = { note: this.formData.internalNotes }
    }

    if (this.formData.paymentNotes) {
      payload.customerNotes = { note: this.formData.paymentNotes }
    }

    try {
      const [res, _] = await Promise.all([
        rservice.addReservationPayment(payload),
        rservice.clearAmountDue(this.reservationIds),
      ])
      if (res.status === 200) {
        this.handleSubmitSuccess()
      }
    } catch (e: any) {
      console.error(e)
      EventBus.$emit('snackbar:error', e.response.data.message)
      this.loading = false
    }
  }

  validatePaymentAmount(val: string): boolean | string {
    if (Number(val) > Number(this.reservationTotalBalance.toFixed(2))) {
      return 'Payment Amount cannot be greater than Reservation Total'
    }
    return true
  }

  handleChangeAddressInput(input: string): void {
    this.formData.address = { ...this.formData.address, street: input }
  }

  handleChangeAddress(address: Address): void {
    if (address) {
      const { street1, postalCode, ...rest } = address
      this.formData.address = { ...rest, street: street1, zip: postalCode }
    }
  }

  created(): void {
    window.addEventListener('message', this.handleMessageEvent)
  }

  beforeDestroy(): void {
    window.removeEventListener('message', this.handleMessageEvent)
  }

  async mounted(): Promise<void> {
    await this.loadPaymentGateways()
    await this.loadContacts()
    await this.loadCustomerPaymentProfiles()
    this.loadDueNow()

    this.sessionId = v4()
  }
}


import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import PaymentSidebarHeader from '@/components/PaymentSidebarHeader.vue'
import sidebar from '@/store/modules/sidebar'
import CUSidebarContent from '@/layouts/CUSidebarContent.vue'
import CUSidebarContentWithFooterButtons from '@/components/CUSidebarContentWithFooterButtons.vue'
import rstate from '@/store/modules/reservation'
import { isNotEmptyInput } from '@/utils/validators'
import customer from '@/services/customer'
import RecipientList from '@/components/RecipientList.vue'
import { formatFullName, currencyFilter } from '@/utils/string'
import {
  PaymentMethod,
  PaymentMethodType,
  PaymentMethodKeys,
  Reservation,
} from '@/models/dto'
import PaymentSidebarBulkReservationsHeader from '@/components/PaymentSidebarBulkReservationsHeader.vue'
import reservation from '@/services/reservation'
import CustomEmailSidebar from '@/components/CustomEmailSidebar.vue'
import invoiceClient from '@/services/invoice'
import { EmailTemplate } from '@/models/dto/Template'
import { apiBaseUrl, baseUrl } from '@/utils/env'
import { Email } from '@/models/dto/Email'
import { EventBus } from '@/utils/eventBus'
import {
  InvoiceSettings,
  InvoiceSettingsPaymentMethod,
} from '@/models/dto/Invoice'
import { SimpleTableColumn } from '@/models/SimpleTableColumn'
import PaymentLinkCell from './PaymentLinkCell.vue'
import tclient from '@/services/type'
import cclient from '@/services/company'
import { distinctBy } from '@/utils/reducers'
import CUSimpleTable from '@/components/CUSimpleTable.vue'
import { Customer } from '@/models/dto/Customer'
import auth from '@/store/modules/auth'
import payments from '@/services/payments'
import { subject, body } from '@/data/invoiceTemplate'
import messages from '@/data/messages'
import currency from 'currency.js'
import { parseAxiosError } from '@/utils/error'
import quotes from '@/services/quotes'

@Component({
  components: {
    PaymentSidebarHeader,
    CUSidebarContent,
    CUSidebarContentWithFooterButtons,
    RecipientList,
    PaymentSidebarBulkReservationsHeader,
    CUSimpleTable,
  },
})
export default class CreateInvoiceSidebar extends Vue {
  @Prop({ required: false, default: undefined })
  readonly bulkReservations!: Reservation[]

  isNotEmptyInput = isNotEmptyInput
  currencyFilter = currencyFilter
  apiBaseUrl = apiBaseUrl
  loading = true
  contacts = []
  displayedReservations = []
  invoiceNotes = null
  invoiceTemplates = []
  invoicePaymentMethods: InvoiceSettingsPaymentMethod[] = []
  paymentMethodTypes: PaymentMethodType[] = []
  paymentGateways = []
  displayAmount = null
  quote = null

  formData = {
    contactId: null,
    invoiceNumber: '',
    poNumber: '',
    amount: null,
    supportPdf: null,
    supportPaymentLink: false,
    includePaymentHistory: false,
    includeTripDetails: false,
    includePaymentMethodPage: false,
    includeTerms: false,
  }

  @Watch('bulkReservations', { immediate: true })
  onBulkReservationChange(reservations: Reservation[]): void {
    this.displayedReservations = reservations
  }

  get reservationIds(): number[] {
    if (this.displayedReservations) {
      return this.displayedReservations.map((r) => r.reservationId)
    }
    return [rstate.reservation.reservationId]
  }

  get reservationHashes(): string[] {
    if (this.displayedReservations) {
      return this.displayedReservations.map((r) => r.hash)
    }
    return [rstate.reservation.hash]
  }

  get totalBalance(): number {
    if (this.displayedReservations?.length) {
      return this.displayedReservations.reduce(
        (sum: currency, res: Reservation) => currency(res.balance).add(sum),
        currency(0)
      ).value
    } else {
      return currency(rstate.reservation.balance).value
    }
  }

  get invoiceCustomer(): Customer {
    return this.contacts.find((c) => c.customerId === this.formData.contactId)
  }

  get billingCustomer(): Customer {
    if (this.bulkReservations) {
      return null
    }
    return this.contacts.find(
      (c) => c.customerId === rstate.reservation.billingCustomerId
    )
  }

  cancel(): void {
    sidebar.pop()
  }

  async loadContacts(): Promise<void> {
    const res = await customer.tableView({ pageSize: -1 })
    this.contacts = res.data.resultList.map((c) => ({
      ...c,
      name: formatFullName(c),
    }))
  }

  getInvoicePdfLink(baseUrl: string): string {
    let params = ''
    if (this.formData.includePaymentHistory) {
      params = `${params}&itinerary=true`
    }
    if (this.formData.includePaymentMethodPage) {
      params = `${params}&payment=true`
    }
    if (this.formData.includeTerms) {
      params = `${params}&terms=true`
    }
    if (this.formData.includeTripDetails) {
      params = `${params}&trip=true`
    }
    return `https://${baseUrl}/pdf/invoice/reservations?reservationHashes=${this.reservationHashes.join(
      ','
    )}${params}.pdf`
  }

  getGatewayStatus(paymentMethod: InvoiceSettingsPaymentMethod): boolean {
    switch (paymentMethod.paymentMethodType.key) {
      case PaymentMethodKeys.CREDIT_CARD:
        return !!this.paymentGateways.find(
          (gateway) =>
            gateway.paymentGatewayTypeKey === 'auth_net' ||
            gateway.paymentGatewayTypeKey === 'square'
        )
      case PaymentMethodKeys.ACH:
        return false
      case PaymentMethodKeys.CHECK:
        return false
      case PaymentMethodKeys.WIRE:
        return false
      case PaymentMethodKeys.OTHER:
        return false
      default:
        return false
    }
  }

  handleAmountBlur(): void {
    this.formData.amount = Math.min(
      Math.max(parseFloat(this.displayAmount.replace(/[^\d.-]/g, '')), 0),
      this.totalBalance
    )
    if (this.formData.amount === 0) {
      this.deselectInvoicePaymentMethods()
    }
    this.setDisplayAmount()
  }

  setDisplayAmount(): void {
    this.displayAmount = currencyFilter(
      this.reservationIds.length > 1
        ? this.totalBalance
        : this.formData.amount ?? this.totalBalance
    )
  }

  setSupportPdf(): void {
    if (this.formData.supportPdf == null) {
      this.formData.supportPdf = this.invoiceTemplates?.[0]?.includePdf ?? true
    }
  }

  async loadDefaultCompanyPaymentMethods(): Promise<void> {
    const { data } = await cclient.byId(auth.getCompanyId)
    const { company } = data

    if (company.defaultCompanyPaymentMethods?.length) {
      this.invoicePaymentMethods = company.defaultCompanyPaymentMethods.map(
        (defaultCompanyPaymentMethod) => {
          const paymentMethod: PaymentMethod = {
            paymentMethodTypeId:
              defaultCompanyPaymentMethod.paymentMethodTypeId,
            paymentMethodType: defaultCompanyPaymentMethod.paymentMethodType,
            processingFee: defaultCompanyPaymentMethod.processingFee,
            note: defaultCompanyPaymentMethod.note,
            isActive: defaultCompanyPaymentMethod.isActive,
          }
          return paymentMethod
        }
      )
    }
  }

  async loadInvoiceSettings(): Promise<void> {
    let reservationId = rstate.reservation?.reservationId
    if (this.bulkReservations) {
      const oldestRes = this.bulkReservations.sort(
        (a, b) => a.reservationId - b.reservationId
      )[0]
      reservationId = oldestRes.reservationId
    }
    const res = await reservation.getInvoiceSettings(reservationId)

    if (res.data.invoiceSettings) {
      this.formData = Object.assign({}, this.formData, res.data.invoiceSettings)
      if (
        this.formData.amount != null &&
        this.formData.amount > this.totalBalance
      ) {
        this.formData.amount = this.totalBalance
      }
      this.invoicePaymentMethods = res.data.invoiceSettings.paymentMethods
      this.invoiceNotes = res.data.invoiceSettings.invoiceNotes.html
      this.setDisplayAmount()
    } else {
      await this.loadDefaultCompanyPaymentMethods()
    }
  }

  async loadInvoiceTemplates(): Promise<void> {
    const defaultTemplate = new EmailTemplate({
      subject: subject.replaceAll(
        '{{ COMPANY_NAME }}',
        auth.getCompany?.name || 'COMPANY'
      ),
      opener: body.replaceAll(
        '{{ COMPANY_NAME }}',
        auth.getCompany?.name || 'COMPANY'
      ),
      includePdf: true,
    })
    const res = await invoiceClient.getEmailTemplate()
    this.invoiceTemplates = res.data?.emailCustomizationId
      ? [res.data, defaultTemplate]
      : [defaultTemplate]
  }

  async submit(openSidebar = true): Promise<void> {
    if (!this.$refs['form']['validate']()) {
      return
    }
    this.loading = true

    let reservationId = rstate.reservation?.reservationId
    if (this.displayedReservations) {
      const oldestRes = this.displayedReservations.sort(
        (a, b) => a.reservationId - b.reservationId
      )[0]
      reservationId = oldestRes.reservationId
    }

    const payload: InvoiceSettings = {
      ...this.formData,
      reservationId,
      invoiceNotes: { note: this.invoiceNotes, html: this.invoiceNotes },
      paymentMethods: this.invoicePaymentMethods,
    }

    try {
      await reservation.createInvoiceSettings(reservationId, payload)
    } catch (e: any) {
      console.error(e)
      EventBus.$emit('snackbar:error', e.response.data.message)
      this.loading = false
    }

    if (openSidebar) {
      let invoiceHash = rstate.reservation?.hash
      let managedIds = rstate.reservation?.managedId
      if (this.displayedReservations) {
        invoiceHash = this.displayedReservations
          .map((res) => res.hash)
          .join(',')
        managedIds = this.displayedReservations
          .map((res) => res.managedId)
          .join(',')
      }

      const emailTemplates = this.invoiceTemplates.map((t) => {
        return { ...t, includePdf: this.formData.supportPdf }
      })

      sidebar.push({
        component: CustomEmailSidebar,
        props: {
          id: managedIds,
          link: `https://${baseUrl()}/invoice/${invoiceHash}`,
          pdfType: 'Invoice',
          title: 'Invoice',
          customers: this.contacts,
          templates: emailTemplates,
          bookingContact: this.invoiceCustomer,
          billingContact: this.billingCustomer,
          showBackBtn: true,
        },
        width: 500,
        on: { send: (email: Email) => this.sendEmail(email) },
      })
    }
    this.loading = false
  }

  async sendEmail(email: Email): Promise<void> {
    try {
      await invoiceClient.sendInvoiceEmail({
        ...email,
        reservationIds: this.reservationIds,
        pdfLink: this.getInvoicePdfLink('pdf.busify.com'),
        firstName: this.invoiceCustomer.firstName,
        lastName: this.invoiceCustomer.lastName,
        timezone: auth.getUserTimeZone,
      })

      EventBus.$emit('snackbar:success', messages.success.emailSent)
      EventBus.$emit('reservation:update-invoice-last-sent-date')
      EventBus.$emit('refresh-tableview')
      sidebar.close()
    } catch (e: any) {
      EventBus.$emit('snackbar:error', parseAxiosError(e))
    } finally {
      this.loading = false
    }
  }

  handleRemoveBulkReservation(reservationId: number): void {
    this.displayedReservations = this.displayedReservations.filter(
      (res) => res.reservationId !== reservationId
    )
    if (this.displayedReservations.length === 0) {
      sidebar.pop()
    }
  }

  async handlePdfClick(): Promise<void> {
    await this.submit(false)
    const link = this.getInvoicePdfLink(apiBaseUrl('pdf'))
    window.open(link, '_blank')
  }

  get invoicePaymentMethodValues(): InvoiceSettingsPaymentMethod[] {
    return this.invoicePaymentMethods.filter((m) => m.isActive)
  }

  get paymentMethods(): InvoiceSettingsPaymentMethod[] {
    const invoicePaymentMethods = this.invoicePaymentMethods.reduce(
      distinctBy('paymentMethodTypeId'),
      {}
    )
    return this.paymentMethodTypes.map((paymentMethodType) => {
      const quotePaymentMethod = invoicePaymentMethods[paymentMethodType.id]
      if (quotePaymentMethod) {
        return quotePaymentMethod
      }
      const paymentMethodTypeId = paymentMethodType.id
      return {
        quoteId: null,
        processingFee: null,
        note: null,
        paymentMethodTypeId,
        paymentMethodType,
        isActive: false,
      }
    })
  }

  deselectInvoicePaymentMethods(): void {
    for (const method of this.invoicePaymentMethods) {
      method.isActive = false
    }
  }

  paymentMethodHeaders: SimpleTableColumn<InvoiceSettingsPaymentMethod>[] = [
    {
      text: 'Method',
      value: 'paymentMethodType/label',
      type: 'component',
      component: PaymentLinkCell,
      width: 80,
    },
    {
      text: 'Online Processing',
      value: '',
      type: 'text',
      placeholder: '--',
      formatter: (row: InvoiceSettingsPaymentMethod): string =>
        this.getGatewayStatus(row) ? 'Enabled' : '',
    },
    {
      text: 'Processing Fee',
      value: 'processingFee',
      type: 'number',
      valueType: 'percent',
      width: 96,
      align: 'end',
      editable: true,
      reverse: true,
      placeholder: '%',
    },
  ]

  handleToggleSelectAllPaymentMethods(val: {
    items: InvoiceSettingsPaymentMethod[]
    value: boolean
  }): void {
    for (const item of val.items) {
      this.handleUpdateAllowedPaymentMethod({ item, value: val.value })
    }
  }

  handleUpdateAllowedPaymentMethod(val: {
    item: InvoiceSettingsPaymentMethod
    value: boolean
  }): void {
    const invoiceMethods = [...this.invoicePaymentMethods]
    const index = invoiceMethods.findIndex((m) => m === val.item)
    if (index === -1) {
      invoiceMethods.push({ ...val.item, isActive: val.value })
    } else {
      invoiceMethods[index] = { ...val.item, isActive: val.value }
    }
    this.invoicePaymentMethods = invoiceMethods
  }

  handleUpdateInvoicePaymentMethod(paymentMethod: PaymentMethod): void {
    const pm = this.invoicePaymentMethods.find(
      (pm) => pm.paymentMethodType?.id === paymentMethod?.paymentMethodType?.id
    )
    const idx = this.invoicePaymentMethods.indexOf(pm)
    if (idx > -1) {
      const paymentMethods = [...this.invoicePaymentMethods]
      paymentMethods.splice(idx, 1, paymentMethod)
      this.invoicePaymentMethods = paymentMethods
    }
  }

  paymentMethodTypeFilter(types: PaymentMethodType[]): PaymentMethodType[] {
    return (types || []).filter((type) =>
      Object.values(PaymentMethodKeys).includes(type.key)
    )
  }

  async loadPaymentMethodTypes(): Promise<void> {
    const { data } = await tclient.paymentMethodTypes()
    this.paymentMethodTypes = await this.paymentMethodTypeFilter(data)
  }

  async loadPaymentGateways(): Promise<void> {
    const allGatewaysRes = await payments.getAllPaymentGateways()
    this.paymentGateways = allGatewaysRes?.data?.paymentGateways || []
  }

  async loadQuote(): Promise<void> {
    const quote = await quotes.byId(
      rstate.reservation?.quoteId || this.bulkReservations?.[0]?.quoteId
    )
    this.quote = quote?.data?.quote
  }

  loadFormDataContactId(): void {
    this.formData.contactId =
      rstate.reservation?.customerId || this.bulkReservations?.[0]?.customerId
  }

  setPONumber(): void {
    if (!this.formData.poNumber && this.quote?.poNumber) {
      this.formData.poNumber = this.quote?.poNumber
    }
  }

  async mounted(): Promise<void> {
    this.formData.amount = this.totalBalance
    this.setDisplayAmount()

    await Promise.all([
      this.loadInvoiceTemplates(),
      this.loadPaymentMethodTypes(),

      this.loadInvoiceSettings(),
      this.loadContacts(),
      this.loadFormDataContactId(),
      this.loadPaymentGateways(),
      this.loadQuote(),
    ])

    this.setSupportPdf()
    this.setPONumber()
    this.loading = false
  }
}
